

























import { SfTabs } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import ProfileUpdateForm from '~/modules/customer/pages/MyAccount/MyProfile/ProfileUpdateForm.vue';
import PasswordResetForm from '~/modules/customer/pages/MyAccount/MyProfile/PasswordResetForm.vue';
import type { OnFormComplete, OnFormError, SubmitEventPayload } from '~/modules/customer/types/form';
import type { ProfileUpdateFormFields, PasswordResetFormFields } from '~/modules/customer/pages/MyAccount/MyProfile/types';

export default defineComponent({
  name: 'MyProfile',
  components: {
    SfTabs,
    ProfileUpdateForm,
    PasswordResetForm,
  },

  setup() {
    const {
      changePassword,
      loading,
      updateUser,
      error,
    } = useUser();

    const formHandler = async <T extends () => Promise<unknown>>(
      onSubmit: T,
      onComplete: OnFormComplete,
      onError: OnFormError,
    ) => {
      await onSubmit();
      const actionErr = error.value.changePassword || error.value.updateUser;
      if (actionErr) {
        onError(actionErr.toString());
      } else {
        onComplete();
      }
    };

    const updatePersonalData = ({
      form,
      onComplete,
      onError,
    } : SubmitEventPayload<ProfileUpdateFormFields>) => formHandler(
      async () => updateUser({ user: form }),
      onComplete,
      onError,
    );

    const updatePassword = ({
      form,
      onComplete,
      onError,
    } : SubmitEventPayload<PasswordResetFormFields>) => formHandler(
      async () => changePassword({ current: form.currentPassword, new: form.newPassword }),
      onComplete,
      onError,
    );

    return {
      loading,
      updatePersonalData,
      updatePassword,
    };
  },
});
