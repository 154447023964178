var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submitForm(reset))}}},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"password","name":"currentPassword","label":_vm.$t('Current Password'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"password","name":"newPassword","label":_vm.$t('New Password'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"password","name":"repeatPassword","label":_vm.$t('Repeat Password'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})]}}],null,true)})],1),_vm._v(" "),_c('BKButton',{staticClass:"form__button",attrs:{"type":"submit","data-testid":"update-password-submit"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('Update password'))+"\n      ")]},proxy:true}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }